export const RULE_TYPES = [{
    title: 'Allgemeines',
    value: 'general',
    slides: [{
        title: 'Philosophie',
        description: 'Das Turnier steht im Zeichen des Entertainmentfaktors. Es wurde sorgfältig balanciert und organisiert, um Spaß für TeilnehmerInnen und ZuschauerInnen zu gewährleisten. Fairness und Sportsgeist sind unsere höchsten Prinzipien. Respektloses Verhalten, Regelverstöße zur Vorteilserlangung oder Missachtung der Organisation können zu Konsequenzen wie Punktabzug, Ausschluss oder Verlust des Preisgeldes führen.'
    }, {
        title: 'Regelverstoß',
        description: 'Regelverstöße und technische Probleme werden demokratisch von der Projektorganisation behandelt. Maßnahmen werden intern abgestimmt und bekannt gegeben. Bei längeren Untersuchungen können nach dem Event noch Punkte oder Platzierungen angepasst werden, um den Livestream-Charakter nicht zu beeinträchtigen.'
    }, {
        title: 'Kommunikation',
        description: 'Die Organisation teilt den TeilnehmerInnen im Voraus (über Discord) alle benötigten Informationen und Ressourcen (wie Programme, Mods usw.) mit, um einen reibungslosen Ablauf sicherzustellen. Es wird erwartet, dass alle TeilnehmerInnen diesen Anweisungen folgen und ihre Setups bereits 48h vor beginn aufgesetzt haben.'
    }, {
        title: 'Fragen',
        description: 'TeilnehmerInnen sollten bei Unklarheiten die internen Discord-Channels nutzen, um Fragen zu stellen und Klarheit zu erhalten. Bei Verstoß gegen diese Vorgabe, ob bewusst oder unbeabsichtigt, erfolgt eine Strafe. Dies gilt auch für Grauzonen.'
    }, {
        title: 'Zeitmessung',
        description: 'Die Zeitmessung erfolgt mithilfe einer Mod. Es gibt eine maximale Echtzeitgrenze, die nicht überschritten werden darf. Diese beträgt 30 Minuten oder wird im Discord anders kommuniziert. Für das Turnier wird die Spielzeit gewertet, wobei Ladebildschirme und das ESC-Menü von der Gesamtspielzeit abgezogen werden.'
    }, {
        title: 'Livestream',
        description: 'Während des Events müssen alle TeilnehmerInnen auf ihrem Twitch- oder YouTube-Kanal live sein. Es ist nicht erlaubt, Koordinaten oder andere Spielbereiche zu zensieren, außer für Facecam/Handcam und Sponsoren usw. Das Streaming darf ohne Verzögerung (Delay) erfolgen. Zusätzlich muss das Spiel während des gesamten Laufs (vom Seed-Eintrag bis zum Verlassen der Karte) kontinuierlich im Stream sichtbar sein.'
    }]
}, {
    title: 'peepoSprint',
    value: 'peeposprint',
    slides: [{
        title: 'Turnierformat',
        description: 'peepoSprint ist ein Minecraft Speedrun Turnier, welches entweder als Einzel- oder Teamwettbewerb stattfindet. Beim Einzelwettbewerb spielt jeder für sich, während in Teamformaten die Punkte der Spieler summiert werden.'
    }, {
        title: 'Ablauf und Wertung',
        description: 'Es werden immer mehrere Seeds gespielt und am Ende ein Gesamtergebnis auf der Website und im Stream präsentiert. Zusätzlich gibt es oft Kategorien wie die beste Zeit, die mit eigenem Preisgeld prämiert werden.'
    }, {
        title: 'Client',
        description: 'Als Minecraft-Client wird ein FabricMC-Mod-Setup verwendet. Die "peepoSprint"-Mod wird mithilfe eines Installers zusammen mit Fabric eingerichtet. Die Turniermod ist mit den "peepoSprint"-Servern verbunden und überträgt Installations- und Ingame-Daten. Diese sind unter anderem für das Leaderboard notwendig.'
    }, {
        title: 'Mods',
        description: 'Das Turnier findet ausschließlich in der Minecraft-Version 1.16.1 statt. Jegliche Modifikationen, die nicht im Spiel selbst vorgenommen werden können, sind verboten, es sei denn die Organisation hat anders kommuniziert. Beispiele dafür sind OptiFine, Fullbright, BLC usw.'
    }, {
        title: 'Difficulty',
        description: 'Die Schwierigkeitsstufe muss vor der Generierung der Welt auf "Easy" gestellt werden. Während des Laufs ist es erlaubt, die Schwierigkeitsstufe jederzeit zu ändern, aber der Wechsel zu "Peaceful" ist nicht gestattet.'
    }, {
        title: 'Settings',
        description: 'Die "options.txt"-Datei darf nicht bearbeitet werden, außer um den Gamma-Wert auf 5.0 einzustellen oder mit der Organisation abgestimmt. Die Verwendung von Ressourcenpaketen ist untersagt. Das Vanilla-Minecraft-Ressourcenpaket muss verwenden werden.'
    }]
}, {
    title: 'Mod',
    value: 'mod',
    slides: [{
        title: 'Spieler Queue und Reset',
        description: 'Es gibt eine Queue, damit die Spieler gleichzeitig in die Welt starten. Je nach Format wird dies im Discord genauer erklärt. Ebenso gibt es eine Reset-Funktion, welche euch die Welt neu starten lässt. Je nach Format gibt es einen "free" Reset und ansonsten wird die vorherige Ingame-Zeit hinzugezählt.'
    }, {
        title: 'Seed-based Drops',
        description: 'Die meisten Drops werden anhand des Seeds berechnet und sollten daher für alle Spieler und nach den Resets gleich bleiben.'
    }, {
        title: 'Enderdrache',
        description: 'Die peepoSprint-Mod erlaubt es, einen "Dragon Perch" zu fokussieren. Hierzu muss die Z-Koordinate größer als 60 oder kleiner als -60 sein, und der Drachenkampf muss mindestens 60 Sekunden andauern. "Zero Cycles" bleiben weiterhin möglich. Zusätzlich gibt es einen Indikator, der anzeigt, ab wann der Force Perch verfügbar ist sowie den momentanen Drachen-Status.'
    }, {
        title: 'Piglin Trades',
        description: 'Die Mod passt die Handelsraten der Piglins an. Dabei ist sichergestellt, dass bei jedem 7. Handel ein Obsidian, bei jedem 13. Handel 10 Fäden, bei jedem 20. Handel 4 Enderperlen und bei jedem 33. Handel ein Trank mit Feuerresistenz-Effekt fallengelassen wird.'
    }, {
        title: 'Gravel und Iron Golem Drops',
        description: 'Ein Eisengolem lässt immer 5 Eisen fallen. Bei Gravel ist sicher, dass jeder dritte Gravel mindestens einen Flint fallen lässt. Verzauberungen haben keinen Einfluss darauf.'
    }, {
        title: 'Blaze Rods',
        description: 'Die Blaze-Rod-Droprate wurde überarbeitet. Jetzt fällt bei jeder Blaze mindestens eine Blaze Rod, falls die vorherige Blaze keine gedroppt hat. Looting-Verzauberungen wirken weiterhin.'
    }]
}, {
    title: 'Punkte',
    value: 'points',
    slides: [{
        title: 'Punkte Wertung',
        description: 'Ein Spieler erhält Gesamtpunkte basierend auf den in Checkpoints gesammelten Punkten sowie Bonuspunkten für die Platzierung im Leaderboard, sofern der Lauf erfolgreich abgeschlossen wurde. Tipp: Aufgeben führt meist zu einer schlechten Platzierung, da andere den Run später finishen können und damit vor dich kommen.'
    }, {
        title: 'Checkpoints',
        description: 'Insgesamt gibt es 4 Checkpoints: Start, Nether In, Ende In und Finished. Jeder Checkpoint enthält Fortschrittspunkte. Sobald der nächste Checkpoint erreicht wird, gilt der vorherige automatisch als erfüllt und vergibt alle Punkte, selbst wenn nicht alle Fortschrittspunkte gesammelt wurden. Im Nether werden 6 Blaze Rods und 12 Enderperlen gesammelt. Im Ende werden die Punkte basierend auf der Drachengesundheit berechnet.'
    }, {
        title: 'Map Leaderboard',
        description: 'Das Map-Leaderboard zeigt die individuellen Läufe der Spieler. Für jeden Run wird ein separates Leaderboard mit Zwischenergebnissen erstellt. Dieses enthält Live-Ergebnisse und aktualisiert sich kontinuierlich mit den neuen Spielerdaten. Je nach Modus, wie beispielsweise "Tag Team", können Erweiterungen hinzukommen.'
    }, {
        title: 'Globales Leaderboard',
        description: 'Das Globale Leaderboard bestimmt den Turniergewinner. Es summiert die Ergebnisse der Map-Leaderboards abhängig vom Modus. Dieses Leaderboard wird kontinuierlich mit den aktuellen Daten der Teilnehmenden aktualisiert.'
    }, {
        title: 'Map Punkte Schlüssel',
        description: 'Die Mappunkte variieren je nach Gamemode. In der Regel wird die Anzahl der Spieler auf der Map oder im Turnier +1 minus dem Rang des Spielers genommen. Also bei 8 Spielern bekommt der 1. Platz 8 Punkte, 2. Platz 7 Punkte, usw.'
    }]
},]
