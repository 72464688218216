import React from 'react';
import styles from './XperionSlide.module.css';
import Button from '../../../../../../components/button/Button';
import XperionLogo from '../../../../../../assets/website/partner/xperion_1o1.png';

type XperionProps = {};

export const XperionSlide: React.FC<XperionProps> = (props) => {
    return (
        <div className={styles.nzSlide}>
            <div className={styles.textContainer}>
                <h2>XPERION</h2>
                <p className={styles.subtitle}>
                    XPERION STEHT FÜR GAMING OHNE GRENZEN.
                </p>
                <p>
                    Das bedeutet Gaming auf allen möglichen Systemen, ob auf dem PC, der XBOX, der PlayStation 5 oder
                    sogar VR Brillen, hier ist alles möglich und hier ist alles kostenlos - in Köln und Berlin auf 3000
                    bzw. 2300 qm und jeweils weit über 150 Konsolen und PC Setups.
                    <br/><br/>
                    Xperion ist unsere Location und Partner für das peepoSprint Offline-Event. Schaut auch gerne mal
                    auf ihrem Instagram vorbei, um aktuelle Eindrücke zu bekommen.
                </p>
                <div className={styles.nzButtons}>
                    <a href={'https://www.instagram.com/xperion_germany/?hl=de'} target={'_blank'}>
                        <Button color={'secondary'}>Instagram</Button>
                    </a>
                </div>
            </div>
            <a href={'https://www.instagram.com/xperion_germany/?hl=de'} target={'_blank'}>
                <img src={XperionLogo} className={styles.xperionLogo} alt={''}/>
            </a>
        </div>
    );
};
