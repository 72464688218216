import * as React from 'react';
import styles from './SmallIntroSection.module.css';
import {IntroductionPaper} from './component/IntroductionPaper';
import {Link, NavLink} from "react-router-dom";
import Button from "../../../components/button/Button";
import {LIVE_EVENT_ROUTE} from "../../../router/routerUrls";

export const SmallIntroSection: React.FC = (props) => {
    return (
        <div className={`section ${styles.welcomeContainer}`}>
            <div className={styles.introHeader}>
                <h1>informationen</h1>
                <p>Das peepoSprint Speedrun Invitational als erstes Turnier seiner<br/> Art im deutschsprachigen Raum
                </p>
            </div>

            <div className={styles.introBody}>
                <h2>peepoSprint?</h2>
                <p className={styles.textBlock}>Das peepoSprint Speedrun Invitational ist das erste Turnier seiner Art
                    im deutschsprachigen Raum! Doch was ist dieses peepoSprint überhaupt?</p>
                <p>peepoSprint ist ein Minecraft-Speedrun-Turnier, bei dem professionelle Speedrunner und Streamer im
                    Teamformat gegeneinander antreten. <b>Jetzt erstmals am 13.07 in Köln!</b></p>
                <div className={styles.buttonContainer}>
                    <Link to={'https://app.tickettailor.com/events/peeposprint/1293088'} rel={'noopener noreferrer'}
                          style={{paddingRight: 30}}>
                        <Button color={'primary'}>Tickets für Köln</Button>
                    </Link>
                    <NavLink to={LIVE_EVENT_ROUTE}>
                        <Button color={'primary'}>Zum Event</Button>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};
