import * as React from 'react';
import {PartnerContainer} from '../../components/partners/PartnerContainer';
import styles from './OfflineEventPage.module.css';
import {MonsterPaper} from "../../components/paper/MonsterPaper";
import {BlockTransition} from '../../components/transition/BlockTransition';
import {OfflineInfoSection} from './infos/OfflineInfoSection';
import {useIsSmallScreen} from '../../hooks/useIsSmallScreen';

export const OfflineEventPage: React.FC = (props) => {
    let {isSmallScreen} = useIsSmallScreen();

    return (
        <React.Fragment>
            <div className={`section ${styles.gradientBackground}`}>
                <div className={'page-wrapper center'}>
                    <div className={styles.titleContainer}>
                        <h1>Timetable</h1>
                        <div className={styles.locationContainer}>
                            <h3 className={styles.offlineTitle}>peepoSprint hosted by XPERION</h3>
                            <h4 className={styles.offlineSubtitle}>13.07.2024</h4>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <MonsterPaper>
                            <div className={styles.entryContainer}>
                                <h3 className={styles.offlineTitle}>Ablauf des Events:</h3>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>12:00</div>
                                    <div className={styles.timetableEvent}>Einlass (XPERION)</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>13:00</div>
                                    <div className={styles.timetableEvent}>Einlass & Bändchenverteilung (Foyer)</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>13:15</div>
                                    <div className={styles.timetableEvent}>Meet and Greet (45 Minuten)</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>14:30</div>
                                    <div className={styles.timetableEvent}>Streamstart</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>15:15</div>
                                    <div className={styles.timetableEvent}>Start Seed 1</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>16:20</div>
                                    <div className={styles.timetableEvent}>Start Seed 2</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>17:30</div>
                                    <div className={styles.timetableEvent}>Start Seed 3</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>18:35</div>
                                    <div className={styles.timetableEvent}>Start Seed 4</div>
                                </div>
                                <div className={styles.entryRow}>
                                    <div className={styles.timetableTime}>20:00</div>
                                    <div className={styles.timetableEvent}>Start Seed 5</div>
                                </div>
                            </div>
                        </MonsterPaper>
                    </div>
                    <PartnerContainer/>

                </div>
            </div>
            <BlockTransition align={'top'} orientation={'up'}>
                <OfflineInfoSection small={isSmallScreen}/>
            </BlockTransition>
        </React.Fragment>
    );
};
