import * as React from 'react';
import {useRef} from 'react';
import {TextInput} from '../../../../../../components/form/TextInput';
import Button from '../../../../../../components/button/Button';
import styles from './RbSlide.module.css';
import RedbullCan from '../../../../../../assets/website/partner/rb_can.png';
import {useConfig} from '../../../../../../components/ConfigContext';
import {useSliderContext} from '../../../../../../components/slider/Slider';
import {getFunctions, httpsCallable} from "firebase/functions";

export const RbSlide: React.FC = (props) => {
    const [load, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const config = useConfig();
    const {pauseSlider} = useSliderContext();

    const form = useRef(null);

    const onSubmit = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        if (load) return;

        // @ts-ignore
        let formData = new FormData(e.target);
        setLoading(true);


        fetch('https://usebasin.com/f/55c622fe8b8b', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: formData,
        })
            .then((response) => {
                if (response.status === 200) {
                    setError(false);
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            }).finally(() => setLoading(false));
    };

    const onFormChange = () => {
        // Pause slider for 1 minute after form change to prevent sliding while user is typing
        pauseSlider(60);
    }


    return (
        <div className={styles.rbSlide}>
            <div className={styles.formContent}>
                <h2>Red Bull <br/>Highlights</h2>
                <p className={styles.subtitle}>
                    Gewinne eines von fünf Red Bull Trays indem du einen Clip des peepoSprint
                    Minecraft Speedrun Invitational einsendest.</p>
                <form ref={form} onSubmit={onSubmit} className={styles.rbForm} onChange={onFormChange}>
                    <div>
                        <TextInput name={'email'} type={'email'} placeholder={'your email*'}
                                   required={true}/>
                        <TextInput name={'url'} type={'url'} placeholder={'clip link*'}
                                   required={true}/>
                    </div>
                    <label className={styles.checkboxLabel}>
                        <input type="checkbox" name="consent" required/>
                        <span className={styles.checkboxText}>
                            Ich stimme zu, dass der Veranstalter meine angegebenen Daten zum Zweck der
                            Gewinnbenachrichtigung an Red Bull GmbH weitergeben darf.*
                        </span>
                    </label>
                    <div className={styles.rbSubmitButtons}>
                        <Button type={'reset'} color={'secondary'} disabled={load || success || !config.isEventStarted()}>Reset</Button>
                        <Button type={'submit'} disabled={load || success}>Submit</Button>
                        {!config.isEventStarted() && <p style={{color: 'gray'}}>The event has not started yet!</p>}
                    </div>

                    <div style={{position: 'relative'}}>
                        {success && <p className={styles.successMessage}>You successfully handed in your clip!</p>}
                        {error && <p className={styles.errorMessage}>An error occurred. Try to reload page otherwise contact
                            us!</p>}
                    </div>
                </form>
            </div>
            <div className={styles.brandLogo}>
                <img src={RedbullCan} className={styles.brandLogo}
                     alt={''}/>
            </div>
        </div>
    );
};
