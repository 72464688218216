import * as React from 'react';
import { useState, useEffect } from 'react';
import styles from './Navbar.module.css';
import Button from '../button/Button';
import { PeepoLogo } from '../PeepoLogo';
import { NavLink } from 'react-router-dom';
import {
    HOME_ROUTE,
    INFORMATION_ROUTE,
    OFFLINE_EVENT_ROUTE,
    LIVE_EVENT_ROUTE
} from '../../router/routerUrls';
import { LIVE_LEADERBOARD_SECTION_ID } from '../../pages/live_event/leaderboard/LiveLeaderboardSection';
import { useConfig } from '../ConfigContext';

export function isEventLive(startDate: Date): boolean {
    const currentTime = new Date();
    const targetDateEnd = new Date(startDate.getTime() + 5 * 60 * 60 * 1000);

    return currentTime >= startDate && currentTime <= targetDateEnd;
}

export const Navbar: React.FC = (props) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setScrolled(scrollTop > window.innerHeight); // Adjust this value as needed
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let peepoSprintConfig = useConfig();
    const live = isEventLive(peepoSprintConfig.startDate?.toDate());

    return (
        <div className={`${styles.fixedNavbar} page-wrapper ${scrolled ? styles.scrolled : ''}`}>
            <NavLink to={HOME_ROUTE}><PeepoLogo className={styles.fixedNavbarLogo} /></NavLink>

            <ul className={styles.fixedNavbarItemContainer}>
                <li className={live ? `${styles.fixedNavbarItem} ${styles.live}` : styles.fixedNavbarItem}>
                    <NavLink to={LIVE_EVENT_ROUTE}
                             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                        Event
                    </NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={INFORMATION_ROUTE}
                             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                        Turnier Information
                    </NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={OFFLINE_EVENT_ROUTE}
                             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                        Timetable
                    </NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={LIVE_EVENT_ROUTE + `#${LIVE_LEADERBOARD_SECTION_ID}`} className={styles.navbarLink}>
                        <Button color={'secondary'}>Leaderboard</Button></NavLink>
                </li>
            </ul>
        </div>
    );
};
