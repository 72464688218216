import * as React from 'react';
import {useState} from 'react';
import styles from './PlayerSlide.module.css';
import TwitterLogo from '../../../assets/website/brands/twitter_logo.svg';
import TwitchLogo from '../../../assets/website/brands/twitch_logo.svg';
import EmptyImg from '../../../assets/website/member/empty.png';
import YoutubeLogo from '../../../assets/website/brands/youtube_logo.svg';
import {MemberData} from './player_data';
import {useIsSmallScreen} from "../../../hooks/useIsSmallScreen";
//https://github.com/AaronCCWong/react-card-flip/blob/master/src/ReactCardFlip.tsx

type PlayerSlideProps = {
    small: boolean;
    data: MemberData;
}

export const PlayerSlide: React.FC<PlayerSlideProps> = ({data, small}) => {
    const {name, team, avatar, twitch, youtube, insta, twitter} = data;
    const [showBack, setShowBack] = useState(false);

    const handleClickFlipOver = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setShowBack(!showBack);
    }

    return (
        <div className={styles.playerCardContainer}>
            <div className={`${styles.cardFrontContent} ` + (!showBack ? styles.active : '')}>
                <div className={styles.playerTopControls}>
                    {/*<img className={styles.flipArrow} src={ArrowFlip} alt={'arrow to turn to back side'} onClick={handleClickFlipOver}/>*/}
                </div>
                <div className={styles.avatarContainer} style={{justifyContent: small ? 'right' : 'center'}}>
                    <img src={avatar} className={styles.avatar} alt={`Profile of ${name}`} onError={(e) => {
                        e.currentTarget.src = EmptyImg
                    }}/>
                </div>
                <div className={styles.playerInfoContainer}>
                    <div className={styles.playerSocialsContainer}>
                        <span className={styles.playerTag}>{name}</span>
                        {twitter && <a href={'https://' + twitter} target={'_blank'} rel={'noreferrer'} className={styles.playerLink}>
                            <img src={TwitterLogo} alt={'Twitter'}/>
                        </a>}
                        {twitch && <a href={'https://' + twitch} target={'_blank'} rel={'noreferrer'} className={styles.playerLink}>
                            <img src={TwitchLogo} alt={'Twitch'}/>
                        </a>}
                        {youtube && <a href={'https://' + youtube} target={'_blank'} rel={'noreferrer'} className={styles.playerLink}>
                            <img src={YoutubeLogo} alt={'YouTube'}/>
                        </a>}
                    </div>
                    <span className={styles.teamTag} style={{color: team.color}}>{team.name}</span>
                </div>
            </div>
            {/*<div className={`${styles.cardBackContent} ` + (showBack ? styles.active : '')}>*/}
            {/*    <span>Player</span>*/}
            {/*    <span>Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a,*/}
            {/*        mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut*/}
            {/*        interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class*/}
            {/*        aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos</span>*/}
            {/*</div>*/}
        </div>
    )
        ;
};
