import React from 'react';
import styles from './TwitchStream.module.css';
import {TwitchPlayer} from 'react-twitch-embed';

interface TwitchStreamProps {
    channelName?: string;
    autoplay?: boolean;
    muted?: boolean;
    width?: string | number;
    height?: string | number;
    className?: string;
}

export const TwitchStream: React.FC<TwitchStreamProps> = ({
                                                              channelName = 'veni',
                                                              autoplay = false,
                                                              muted = true,
                                                              width = '100%',
                                                              height = '100%',
                                                              className,
                                                          }) => {
    // Component implementation
    return (
        <TwitchPlayer
            channel={channelName}
            autoplay={autoplay}
            muted={muted}
            width={width}
            height={height}
            className={`${styles.container} ${className}`}
        />
    );
};
