import * as React from 'react';
import {useTabs} from '../../../../components/tabs/TabsProvider';
import Button from '../../../../components/button/Button';
import {RULE_TYPES} from '../rule_data';
import {Tab} from '../../../../components/tabs/Tab';
import {RulesSlide} from './RulesSlide';
import styles from './RulesTabs.module.css';

type RulesTabsProps = {
    small: boolean;
}

export const RulesTabs: React.FC<RulesTabsProps> = ({small}) => {
    const {changeTab} = useTabs();

    const handleChangeTab = (tab: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        changeTab(tab);
    }

    return (
        <div className={styles.rulesSectionContainer}>
            <div className={styles.tabsHeader}>
                {RULE_TYPES.map((section, index) => (
                    <Button key={section.value} onClick={handleChangeTab(section.value)}>{section.title}</Button>
                ))}
            </div>
            <div className={styles.tabsContent}>
                {RULE_TYPES.map((section, index) => (
                    <Tab key={section.value} value={section.value} className={small ? styles.smallTabContent : styles.tabContent}>
                        {section.slides.map((slide) => (<RulesSlide key={slide.title} data={slide} />))}
                    </Tab>
                ))}
            </div>
        </div>
    );
};