// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {connectFirestoreEmulator, doc, getDoc, getFirestore} from 'firebase/firestore';
import {connectDatabaseEmulator, getDatabase, onValue, ref} from 'firebase/database';
import {LeaderboardType} from '../utils/leaderboard_const';
import {MapPlayerLeaderboard, MapTeamLeaderboard, PlayerLeaderboard, TeamLeaderboard} from '../types/leaderboard';
import {connectFunctionsEmulator, getFunctions, httpsCallable} from 'firebase/functions';
import {PeepoSprintConfigData} from '../types/config';
import {Logger} from '../utils/Logger';
import {QualifierLeaderboard} from '../types/qualifier';


const firebaseConfig = {
    apiKey: "AIzaSyD2Dbf6Oo7tfSj1NrGLEoOzdQzmbSQYoTM",
    authDomain: "peeposprint-at.firebaseapp.com",
    projectId: "peeposprint-at",
    storageBucket: "peeposprint-at.appspot.com",
    databaseURL: "https://peeposprint-at-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "95998907280",
    appId: "1:95998907280:web:63eaa50f36e3b999215915"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const realTimeDB = getDatabase(app);
const functions = getFunctions(app, 'europe-west3');

// if (isLocalhost()) {
//     console.log('Using local firestore emulator');
//     connectFirestoreEmulator(db, 'localhost', 8089);
//     connectDatabaseEmulator(realTimeDB, 'localhost', 9000);
//     connectFunctionsEmulator(functions, 'localhost', 5001);
// }

function isLocalhost() {
    const hostname = window.location.hostname;
    return (hostname === 'localhost' || hostname === '127.0.0.1');
}


export function getMapLeaderboard(mapId: string | number, type: LeaderboardType, callback: (data: MapPlayerLeaderboard | MapTeamLeaderboard) => void) {
    const prefix = type === LeaderboardType.TEAM ? 'team_' : '';
    let id = `${prefix}${mapId}`;

    const dbRef = ref(realTimeDB, `/leaderboard/${id}`);
    return onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });
}

export function getGlobalLeaderboard(type: LeaderboardType, callback: (data: PlayerLeaderboard | TeamLeaderboard) => void) {
    const id = type === LeaderboardType.TEAM ? 'team_global' : 'global_best_time';

    const dbRef = ref(realTimeDB, `/leaderboard/${id}`);
    return onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });

}


export const fetchConfig = async (): Promise<PeepoSprintConfigData> => {

    // Load the configuration from Firebase
    const docRef = doc(getFirestore(), 'peepoSprint', 'config');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();

        const expectedFields = ['eventId', 'players', 'teams', 'format'];
        const allFieldsExist = expectedFields.every(field => field in data);

        if (!allFieldsExist) {
            Logger.warn('One or more fields are missing from the config data');
        }
        Logger.debug('Loaded config from Firebase', data);
        return data as unknown as PeepoSprintConfigData;
    } else {
        throw new Error('Could not find a config document in Firebase');
    }
}

export const fetchLeaderboard = async (eventId: string): Promise<QualifierLeaderboard> => {
    const docRef = doc(getFirestore(), 'peepoSprint', eventId, 'leaderboards', 'qualifier');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();
        return data as unknown as QualifierLeaderboard;
    } else {
        throw new Error(`Could not find a leaderboard document in firestore at ${docRef.path}`);
    }
}


export const registerPlayer = async (token: string, email: string) => {
    return httpsCallable(functions, 'qualifier-register')({token, email})
}



