import * as React from 'react';
import {NavbarPlaceholder} from '../../components/navbar/NavbarPlaceholder';
import styles from './Imprint.module.css';

export const Imprint: React.FC = (props) => {
    return (
        <>
            <NavbarPlaceholder/>
            <div className={'page-wrapper center'}>
                <div className={styles.container}>
                    <h2>Impressum</h2>
                    <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
                        Offenlegungspflicht laut §25 Mediengesetz.</p>
                    <br/>
                    <p>Rafael Eisler</p>
                    <p>c/o Kartell07</p>
                    <p>Arbeitergasse 7,</p>
                    <p>1050 Wien,</p>
                    <p>Österreich</p>
                    <br/>
                    <p>Unternehmensgegenstand: Multimedia Agentur</p>
                    <p>UID-Nummer: ATU70335069</p>
                    <p>E-Mail: business@venicraft.at</p>
                    <br/>
                    <p>Mitglied bei: WKW</p>
                    <p>Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at</p>
                    <br/>
                    <p>Berufsbezeichnung: Multimedia Agentur</p>
                    <p>Verleihungsstaat: Österreich</p>
                </div>

            </div>
        </>

    );
};