import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { PeepoSprintConfig } from '../utils/PeepoSprintConfig';
import { Logger } from '../utils/Logger';

const ConfigContext = createContext<PeepoSprintConfig | undefined>(undefined);

type NetworkStateType = {
    data?: PeepoSprintConfig;
    error: boolean;
    errorMessage: string;
    loading: boolean;
    ready: boolean;
};

export const ConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [networkState, setNetworkState] = useState<NetworkStateType>({
        error: false,
        errorMessage: '',
        loading: true,
        ready: false,
    });

    useEffect(() => {
        let isMounted = true;

        PeepoSprintConfig.getInstance().then(configInstance => {
            if (isMounted) {
                Logger.debug('Config loaded', JSON.stringify(configInstance));
                setNetworkState(prevState => ({
                    ...prevState,
                    data: configInstance,
                    ready: true,
                    error: false,
                    loading: false,
                }));
            }
        }).catch(error => {
            if (isMounted) {
                Logger.error('Failed to load config', error.message);
                setNetworkState(prevState => ({
                    ...prevState,
                    error: true,
                    errorMessage: error.message,
                    loading: false,
                }));
            }
            Logger.error('Failed to load config', error);
        });

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <ConfigContext.Provider value={networkState.data}>
            {networkState.ready && children}
            {networkState.error && <p>An error occurred during page load!</p>}
            {networkState.loading && <p>Loading...</p>}
        </ConfigContext.Provider>
    );
};

export const useConfig = (): PeepoSprintConfig => {
    const config = useContext(ConfigContext);
    if (!config) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return config;
};
