import * as React from 'react';
import {useTabs} from '../../../components/tabs/TabsProvider';
import {useIsSmallScreen} from '../../../hooks/useIsSmallScreen';

export const LeaderboardTabIndicator: React.FC = (props) => {

    const tabContext = useTabs();
    const text = tabContext.metaData || 'General';
    const {isSmallScreen} = useIsSmallScreen();
    return (
        <span style={{
            color: '#23F6CD',
            fontSize: '21px',
            fontStyle: 'normal',
            fontWeight: 700,
            margin:  isSmallScreen ? 'auto' : 0,
        }}>{text}</span>
    );
};