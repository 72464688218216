import * as React from 'react';
import {PartnerContainer} from '../../../components/partners/PartnerContainer';
import Accordion from '../../../components/accordion/Accordion';
import styles from './FaqSection.module.css';
import {FAQ_DATA} from './faq_data';

export const FaqSection: React.FC = () => {
    return (
        <div className={styles.container}>
            <div className={'page-wrapper center ' + styles.faqSection}>
                <h2>FAQ</h2>
                {FAQ_DATA && FAQ_DATA.map((faq, index) => (
                    <Accordion title={faq.title} className={styles.accordion}>
                        <p className={styles.faqText}>{faq.description}</p>
                    </Accordion>
                ))}
                <PartnerContainer/>
            </div>
        </div>
    );
};