export const INFO_DATA = [{
    title: 'Allgemeines',
    value: 'general',
    slides: [{
        title: 'Einlass',
        description: 'Beim Einlass ins Foyer (ab 13:00) erhältst du beim Vorzeigen deines Tickets ein Bändchen. ' +
            'Dieses Bändchen gewährt das Verlassen und das Betreten des Events.'
    }, {
        title: 'Meet and Greet',
        description: 'Du hast die Möglichkeit, deine Lieblingsstreamer bei einem Meet and Greet im XPERION zu treffen. ' +
            'Dafür ist ein Zeitfenster von 13:15 bis 14:00 Uhr vorgesehen. Bitte beachte, dass aus Zeitgründen ' +
            'eventuell nicht alle berücksichtigt werden können.'
    }, {
        title: 'Anreise mit Auto',
        description: 'Adresse: XPERION Cologne, Hansaring 97, 50670 Köln. Zum Parken sind beide Parkhäuser (Ritterstr. ' +
            'und Maybachstr.) des Saturns verfübar.'
    }, {
        title: 'Anreise mit ÖPNV',
        description: 'Haltestelle Köln Hansaring (S- oder U-Bahn). ' +
            'Das XPERION ist von der Haltestelle ungefähr 3 Gehminuten entfernt.'
    }, {
        title: 'Verpflegung',
        description: 'Die Mitnahme von Essen und Getränken ist nicht gestattet. ' +
            'Die Location bietet auch vor Ort Getränke und Snacks zum Verkauf an.'
    }, {
        title: 'Jugendschutz',
        description: 'Kinder ab 12 Jahren ohne Begleitung müssen die Location ab 21:00 Uhr verlassen. ' +
            'Mit Begleitung dürfen Kinder ab 12 Jahren bis 22:00 Uhr bleiben.'
    }]
}, {
    title: 'peepoSprint',
    value: 'peeposprint',

    slides: [
        {
            title: 'Turnierformat',
            description: 'peepoSprint ist ein Minecraft Speedrun-Turnier, das sowohl als Einzel- als auch als ' +
                'Teamwettbewerb stattfindet. Im Einzelwettbewerb spielt jeder für sich, während im Teamformat die ' +
                'Punkte der Spieler summiert werden.'
        },
        {
            title: 'Ablauf und Wertung',
            description: 'Es werden mehrere Seeds gespielt und am Ende ein Gesamtergebnis auf der Website und im ' +
                'Stream präsentiert. Zusätzlich gibt es oft Kategorien wie die beste Zeit, die mit eigenem Preisgeld ' +
                'prämiert werden.'
        },
        {
            title: 'Client',
            description: 'Es wird ein FabricMC-Mod-Setup als Minecraft-Client verwendet. Die "peepoSprint"-Mod wird ' +
                'mithilfe eines Installers zusammen mit Fabric eingerichtet. Die Turniermod ist mit den ' +
                '"peepoSprint"-Servern verbunden und überträgt Installations- und Ingame-Daten, die unter anderem ' +
                'für das Leaderboard notwendig sind.'
        },
        {
            title: 'Mods',
            description: 'Das Turnier findet ausschließlich in der Minecraft-Version 1.16.1 statt. Jegliche ' +
                'Modifikationen, die nicht im Spiel selbst vorgenommen werden können, sind verboten, es sei denn, ' +
                'die Organisation hat dies anders kommuniziert. Beispiele hierfür sind Sodium und StandardSettings.'
        },
        {
            title: 'Schwierigkeitsgrad',
            description: 'Die Schwierigkeitsstufe muss vor der Generierung der Welt auf "Easy" gestellt werden. ' +
                'Während des Laufs darf die Schwierigkeitsstufe jederzeit geändert werden, jedoch ist der Wechsel ' +
                'zu "Peaceful" nicht gestattet.'
        },
        {
            title: 'Einstellungen',
            description: 'Die "options.txt"-Datei darf nicht bearbeitet werden, außer um den Gamma-Wert auf 5.0 ' +
                'einzustellen oder mit der Organisation abgestimmt. Die Verwendung von Ressourcenpaketen ist ' +
                'untersagt. Es muss das Vanilla-Minecraft-Ressourcenpaket verwendet werden.'
        }
    ]
}]
