import * as React from 'react';
import styles from './MapPlayerLeaderboardRow.module.css';
import {ProgressBar} from '../../../../../components/progressbar/ProgressBar';
import {PlayerLeaderboardEntry, PlayerMapLeaderboardEntry} from '../../../../../types/leaderboard';
import {Checkpoint} from '../../../../../utils/leaderboard_const';
import {getTime} from '../../../../../utils/timing';
import {useConfig} from '../../../../../components/ConfigContext';

interface MapLeaderboardRowProps {
    row: PlayerMapLeaderboardEntry | PlayerLeaderboardEntry;
}

export const MapPlayerLeaderboardRow: React.FC<MapLeaderboardRowProps> = ({row}) => {
    const {rank, playerName, team, progressValue, ticks, progressPoints, checkpoint} = row as PlayerMapLeaderboardEntry;
    const peepoSprintConfig = useConfig();
    const color = rank % 2 === 0 ? 'transparent' : 'var(--secondary-dark)';
    const timeString = checkpoint === Checkpoint.FINISHED ? getTime(ticks) : '-';
    const teamString = peepoSprintConfig.getTeamById(team).name;
    const points = String(progressPoints?.toFixed(2)).padStart(2, '0');
    const prettyName = peepoSprintConfig.getPlayer(playerName).name;

    return (
        <div className={styles.mapLeaderboardRow} style={{['--row-color' as any]: color}}>
            <span className={styles.rank}>{rank}</span>
            <span className={styles.name}>{prettyName}</span>
            <span className={styles.team}>{teamString}</span>
            <ProgressBar className={styles.progressBar} progress={progressValue} />
            <span className={styles.checkpoint}>{checkpoint?.toLowerCase()}</span>
            <span className={styles.time}>{timeString}</span>
            <span className={styles.points}>{points}P</span>
        </div>
    );
};
