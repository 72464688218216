import React from 'react';
import styles from './ProgressBar.module.css';

interface ProgressBarProps {
    height?: number;
    progress: number;
    className?: string; // Hier wird die className Prop hinzugefügt
}

export const ProgressBar: React.FC<ProgressBarProps> = ({height = 5, progress, className}) => {

    const dots = [
        { id: 0, filled: 0 <= progress, left: '0%' },
        { id: 1, filled: 0.15 <= progress, left: '15%' },
        { id: 2, filled: 0.68 <= progress, left: '67%' },
        { id: 3, filled: 1 <= progress, left: '98%' },
    ];

    return (
        <div className={`${styles.container} ${className}`} style={{['--bar-height' as any]: `5px`}}>
            <div className={styles.progressBar} style={{['--progress' as any]: progress}}/>
            <div className={styles.pointContainer}>
                {dots.map((dot) => (
                    <div key={dot.id} className={`${styles.point} ${dot.filled ? styles.reached : ''}`} style={{left: dot.left}}/>
                ))}
            </div>

        </div>
    );
};
