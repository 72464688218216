import React from 'react';
import styles from './StreamSection.module.css';
import {TwitchStream} from '../../../components/twitch/TwitchStream';

interface StreamSectionProps {
    // Props definition
}

export const StreamSection: React.FC<StreamSectionProps> = () => {
    // Component implementation
    return (
        <div className={styles.container}>
            <TwitchStream width={'100%'} height={'95vh'} muted={true} autoplay={true} channelName={'veni'}/>
        </div>
    );
};
