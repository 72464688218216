import * as React from 'react';
import {CountdownSection} from './sections/countdown/CountdownSection';
import {HomeSliderSection} from './sections/partner/HomeSliderSection';
import {HomeLeaderboardSection} from './sections/leaderboard/HomeLeaderboardSection';
import {LiveStreamPaperSection} from './sections/live/LiveStreamPaperSection';
import {BlockTransition} from '../../components/transition/BlockTransition';

export const HomePage: React.FC = (props) => {
    return (
        <React.Fragment>
            <BlockTransition align={'bottom'} orientation={'up'}>
                <CountdownSection/>
            </BlockTransition>
            <BlockTransition align={'bottom'} orientation={'up'}>
                <LiveStreamPaperSection/>
            </BlockTransition>
            <HomeLeaderboardSection/>
            <HomeSliderSection/>
        </React.Fragment>
    );
};