import * as React from 'react';
import Button from '../../../../components/button/Button';
import {useTabs} from '../../../../components/tabs/TabsProvider';

interface LeaderboardTabButtonProps {
    title?: string;
    value?: string;
    className?: string;
}

export const LeaderboardTabButton: React.FC<LeaderboardTabButtonProps> = ({title, value, className}) => {
    const {changeTab, setMetaData, value: value2} = useTabs();
    const isSelected = value === value2;
    const pressChangeTab = (e: React.MouseEvent) => {
        if(isSelected){
            e.preventDefault();
            return;
        }
        changeTab(value);
        setMetaData(title);
    }


    return (
        <Button color={'secondary'} onClick={pressChangeTab} selected={isSelected} className={className}>
            {title?.toUpperCase()}
        </Button>
    );
};
