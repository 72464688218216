export const FAQ_DATA = [{
    title: 'Was ist peepoSprint Offline?',
    description: 'peepoSprint findet nun erstmals als Event mit Zuschauern vor Ort statt. Das heißt, ihr könnt euren Pros und Streamern live vor Ort oder wie gewohnt von Zuhause aus dem Stream folgen. Das Event findet am 13.07 in Köln im XPERION statt.'
}, {
    title: 'Was war der Qualifier?',
    description: 'Der Open Qualifier ging über zwei Phasen und beinhaltete zuerst den Kampf um die Top 16 des Leaderboards, wobei die Wertung anhand der MCSR Elo erfolgte. Die Top 2 bekamen die ersten 2 Plätze und die restlichen 4 wurden unter den Plätzen 3-16 in einem Turnierformat ausgewählt.'
}, {
    title: 'Wo finde ich die Teilnehmer?',
    description: 'Die Teilnehmer werden auf der "Event"-Seite angezeigt. Ebenso findest du dort ihre Socials und welchem Team sie angehören.'
}, {
    title: 'Woher kommt die Idee?',
    description: 'Wir hatten 2021 die Idee, Speedrunning für die breite Masse zugänglicher zu machen. In diesem Zug haben wir uns dazu entschlossen, peepoSprint V1 zu veranstalten.'
}, {
    title: 'Was sind Tag-Teams?',
    description: 'Um den educational Faktor des Turniers zu erhöhen, haben wir uns dazu entschlossen, dass die Teams aus einem Pro und einem Streamer bestehen. So wollen wir Entertainment und Professionalität vereinen.'
}, {
    title: 'Wer steckt hinter peepoSprint?',
    description: 'PeepoSprint wird von diversen Personen organisiert. Ganz vorne mit dabei sind: Veni aka Rafi, Dweags aka Maxi, das Studio Team von Netrock.at und DerBanko.'
}, {
    title: 'Werden andere Spiele gespielt?',
    description: 'Initial bestand die Idee darin, peepoSprint unabhängig von Minecraft zu gestalten. Dies haben wir auch weiterhin beibehalten und werden in Zukunft vielleicht auch andere Spiele in das Turnier einbinden.'
}, {
    title: 'Wie kann ich mitmachen?',
    description: 'Momentan ist peepoSprint noch ein geschlossenes Turnier. Wir planen aber in Zukunft etwas Großes, also seid gespannt und vielleicht bekommt auch ihr die Chance zu glänzen.'
}, {
    title: 'Was ist Relay Race?',
    description: 'Relay Race oder zu Deutsch Staffellauf ist ein Format, bei dem mehrere Teams gegeneinander antreten. Die Teams bestehen aus Pro, Rookie und Streamer. Die Spieler wechseln sich zwischen den Maps ab und es gibt keine fixe Reihenfolge. Jeder Spieler muss gleich oft gespielt haben.'
}]
