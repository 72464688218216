import * as React from 'react';
import styles from './LiveStreamPaper.module.css';
import {Paper} from '../../../../../components/paper/Paper';
import Button from '../../../../../components/button/Button';
import {TwitchStream} from '../../../../../components/twitch/TwitchStream';
import {Link, NavLink} from 'react-router-dom';
import {INFORMATION_ROUTE, LIVE_EVENT_ROUTE} from '../../../../../router/routerUrls';

export const LiveStreamPaper: React.FC = (props) => {
    return (
        <Paper classname={`page-wrapper`}>
            <div className={styles.contentContainer}>
                <h2>MC Speedrun<br/>Tournament</h2>
                <p>Produziert aus Österreich sind wir eines der Ersten Speedrun Turniere im DACH Raum. Erstmalig findet peepoSprint nun auch als "Offline" Event statt.
                <br/><br/>Hierfür finden wir uns am 13.07 im XPERION in Köln zusammen. Sichert euch jetzt eines der 180 Tickets und sei live vor Ort dabei!</p>
                <div className={styles.buttonContainer}>
                    <Link to={'https://app.tickettailor.com/events/peeposprint/1293088'}>
                        <Button color={'secondary'}>TICKET KAUFEN</Button>
                    </Link>
                    <NavLink to={LIVE_EVENT_ROUTE}>
                        <Button>ZUM EVENT</Button>
                    </NavLink>
                </div>
            </div>

            <TwitchStream width={600} height={400} className={styles.playerContainer}/>

        </Paper>
    );
};


