import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import {
    IMPRINT_ROUTE,
    PRIVACY_ROUTE,
    LIVE_EVENT_ROUTE,
    INFORMATION_ROUTE,
    OFFLINE_EVENT_ROUTE,
    HOME_ROUTE,
    PREVIEW_ROUTE
} from './routerUrls';
import {Imprint} from '../pages/utility/Imprint';
import {Privacy} from '../pages/utility/Privacy';
import {useIsSmallScreen} from '../hooks/useIsSmallScreen';
import {LiveEventPage} from '../pages/live_event/LiveEventPage';
import {LastEventsPage} from '../pages/last_events/LastEventsPage';
import {HomePage} from '../pages/home/HomePage';
import {PreviewPage} from '../pages/preview/PreviewPage';
import {SmallScreenPage} from '../pages/small/SmallScreenPage';
import {InformationPage} from '../pages/information/InformationPage';
import {SmallLiveEventPage} from "../pages/live_event/SmallLiveEventPage";
import {SmallInformationPage} from "../pages/information/SmallInformationPage";
import {OfflineEventPage} from "../pages/offline_event/OfflineEventPage";


export const PeepoRoutes: React.FC = (props) => {
    const {isSmallScreen} = useIsSmallScreen();
    return (
        <Routes>

            {isSmallScreen && <>
                <Route path={HOME_ROUTE} element={<SmallScreenPage/>}/>
                <Route path={INFORMATION_ROUTE} element={<SmallInformationPage/>}/>
                <Route path={LIVE_EVENT_ROUTE} element={<SmallLiveEventPage/>}/>
                <Route path={OFFLINE_EVENT_ROUTE} element={<OfflineEventPage/>}/>
            </>}

            <Route path={IMPRINT_ROUTE} element={<Imprint/>}/>
            <Route path={PRIVACY_ROUTE} element={<Privacy/>}/>

            {!isSmallScreen && <>
                <Route path={LIVE_EVENT_ROUTE} element={<LiveEventPage/>}/>
                <Route path={INFORMATION_ROUTE} element={<InformationPage/>}/>
                <Route path={OFFLINE_EVENT_ROUTE} element={<OfflineEventPage/>}/>
                <Route path={HOME_ROUTE} element={<HomePage/>}/>
            </>}

            <Route path={PREVIEW_ROUTE} element={<PreviewPage/>}/>
        </Routes>
    );
};
