import React from 'react';
import styles from './IconText.module.css';
import {Link} from 'react-router-dom';

interface IconTextProps {
    iconPath: string;
    text: string;
    route: string;
}

export const IconText: React.FC<IconTextProps> = ({ iconPath, text, route }) => {
    return (
        <Link to={route} className={styles.container}>
            <img src={iconPath} alt="Icon" className={styles.icon} />
            <span className={styles.text}>{text}</span>
        </Link>
    );
};
