import * as React from 'react';
import {RulesSection} from './rules/RulesSection';
import {FaqSection} from './faq/FaqSection';
import {BlockTransition} from '../../components/transition/BlockTransition';
import {NavbarPlaceholder} from '../../components/navbar/NavbarPlaceholder';
import {SmallIntroSection} from "./intro/SmallIntroSection";

export const SmallInformationPage: React.FC = (props) => {
    return (
        <React.Fragment>
            <BlockTransition align={'bottom'} orientation={'up'}>
                <NavbarPlaceholder/>
                <SmallIntroSection/>
            </BlockTransition>

            <RulesSection small={true}/>
            <BlockTransition align={'top'} orientation={'down'}>
                <FaqSection/>
            </BlockTransition>

        </React.Fragment>
    );
};
