import * as React from 'react';
import styles from './RulesSlide.module.css';

interface FaqSlideProps {
    data: {
        title: string;
        description: string;
    }
}

export const RulesSlide: React.FC<FaqSlideProps> = ({data}) => {
    return (
        <div className={styles.rulesSlide}>
            <div className={styles.contentSlide}>
                <h3>{data.title}</h3>
                <p>{data.description}</p>
            </div>
        </div>
    );
};