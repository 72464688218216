import React from 'react';
import styles from './IntroductionPaper.module.css';
import {MonsterPaper} from '../../../../components/paper/MonsterPaper';
import {Link, NavLink} from 'react-router-dom';
import {COMING_SOON_ROUTE, INFORMATION_ROUTE, LIVE_EVENT_ROUTE} from '../../../../router/routerUrls';
import Button from '../../../../components/button/Button';

interface IntroductionPaperProps {
    // Props definition
}

export const IntroductionPaper: React.FC<IntroductionPaperProps> = () => {
    // Component implementation
    return (
        <MonsterPaper>
            <h2>peepoSprint?</h2>
            <p className={styles.textBlock}>Das peepoSprint Speedrun Invitational ist das erste Turnier seiner Art
                im deutschsprachigen Raum! Doch was ist dieses peepoSprint überhaupt?</p>
            <p>peepoSprint ist ein Minecraft-Speedrun-Turnier, bei dem professionelle Speedrunner und Streamer im Teamformat gegeneinander antreten. <b>Jetzt erstmals am 13.07 in Köln!</b></p>
            <div className={styles.buttonContainer}>
                <Link to={'https://app.tickettailor.com/events/peeposprint/1293088'} rel={'noopener noreferrer'} style={{paddingRight: 30}}>
                    <Button color={'secondary'}>Tickets für Köln</Button>
                </Link>
                <NavLink to={LIVE_EVENT_ROUTE}>
                    <Button color={'secondary'}>Zum Event</Button>
                </NavLink>
            </div>
        </MonsterPaper>
    );
};
