import * as React from 'react';
import styles from './Partner.module.css';

interface PartnerLogoProps {
    image: string;
    url?: string;
    onClick?: (e: React.MouseEvent) => void;
    small?: boolean;
}

export const PartnerLogo: React.FC<PartnerLogoProps> = ({ image, onClick, url, small }) => {
    const handleClick = (e: React.MouseEvent) => {
        if (url) {
            window.open(url, '_blank');
        }
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <img
            className={styles.partnerLogo + ' ' + (small ? styles.smallLogo : '')}
            src={image}
            onClick={handleClick}
            alt={'Logo of partner of the peepoSprint event'}
        />
    );
};
