import * as React from 'react';
import {TabsProvider} from '../../../components/tabs/TabsProvider';
import {RulesTabs} from './components/RulesTabs';
import {RULE_TYPES} from './rule_data';
import styles from './RulesSection.module.css';
import {Link} from 'react-router-dom';

type RulesSectionProps = {
    small: boolean;
}

export const RulesSection: React.FC<RulesSectionProps> = ({small}) => {
    const defaultVal =  RULE_TYPES.length ? RULE_TYPES[0].value : undefined;

    return (
        <>
            <div className={`section ${styles.container}`}>
                <div className={`page-wrapper center ${styles.rulesSection}`}>
                    <h2>Alles wichtige</h2>
                    <p className={styles.rulesSubtitle}>Alle Infos gibt es hier! Falls du noch offene Fragen hast kannst du uns
                        gerne unter <Link to={'mailto:business@peepoSprint.at'} style={{textDecoration: 'underline'}}>business@peepoSprint.at</Link> kontaktieren.</p>
                   <div className={styles.rulesContent}>
                       <TabsProvider defaultVal={defaultVal}>
                           <RulesTabs small={small} />
                       </TabsProvider>
                   </div>
                </div>
            </div>
        </>

    );
};
