import * as React from 'react';
import {NavbarPlaceholder} from '../../components/navbar/NavbarPlaceholder';
import {StreamSection} from './stream/StreamSection';
import {LiveLeaderboardSection} from './leaderboard/LiveLeaderboardSection';
import {PlayersSection} from './players/PlayersSection';
import {BlockTransition} from '../../components/transition/BlockTransition';
import {PartnerContainer} from '../../components/partners/PartnerContainer';

export const LiveEventPage: React.FC = (props) => {
    return (
        <React.Fragment>
            <NavbarPlaceholder/>
            <StreamSection/>
            <BlockTransition align={'bottom'} orientation={'up'}>
                <LiveLeaderboardSection/>
            </BlockTransition>
            <PlayersSection small={false}/>
            <PartnerContainer/>
        </React.Fragment>
    );
};