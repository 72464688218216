import * as React from 'react';
import {NavbarPlaceholder} from '../../components/navbar/NavbarPlaceholder';
import {LiveLeaderboardSection} from './leaderboard/LiveLeaderboardSection';
import {PlayersSection} from './players/PlayersSection';
import {BlockTransition} from '../../components/transition/BlockTransition';
import {PartnerContainer} from '../../components/partners/PartnerContainer';

export const SmallLiveEventPage: React.FC = (props) => {
    return (
        <React.Fragment>
            <NavbarPlaceholder />
            <BlockTransition align={'bottom'} orientation={'up'}>
                <LiveLeaderboardSection />
            </BlockTransition>
            <PlayersSection small={true} />
            <PartnerContainer />
        </React.Fragment>
    );
};