import * as React from 'react';
import styles from './Paper.module.css';

interface PaperProps {
    children?: React.ReactNode;
    color?: 'primary' | 'secondary' | string;
    lightColor?: string;
    classname?: string;
    pattern?: boolean;
}

type cssColors = 'primary' | 'secondary' | 'bg' | 'bgDark' | 'bgLight';

export const Paper: React.FC<PaperProps> = ({children, color = 'primary', lightColor, classname, pattern = true}) => {
    const useColor = (color === 'primary' || color === 'secondary') ? 'var(--' + color + ')' : color;
    const lColor = (color === 'primary' || color === 'secondary') ? 'var(--' + color + '-light)' : lightColor;
    const paperClass = `center ${pattern ? `${styles.paper} ${styles.pattern}` : styles.paper}`;

    return (
        <div className={classname ? `${paperClass} ${classname}`: paperClass} style={{ ['--light-color' as any]: lColor, ['--base-color' as any]: useColor }}>
            {children}
            <div className={styles.colorLayer} style={{ ['--light-color' as any]: lColor }}/>
            <div className={styles.colorLayer2} style={{ ['--light-color' as any]: lColor }}/>
        </div>
    );
};