import { EventFormat, Player } from '../types/config';
import { fetchConfig } from '../firebase/firebase';
import { Logger } from './Logger';
import { Timestamp } from 'firebase/firestore';
import { TeamN } from '../types/teams';

export class PeepoSprintConfig {
    private static instance: PeepoSprintConfig;
    private static loadingPromise: Promise<PeepoSprintConfig> | null = null;

    public eventId: string;
    public players: Player[];
    public teams: TeamN[];
    public format: EventFormat;
    public startDate: Timestamp;

    private constructor() {
        this.eventId = '';
        this.players = [];
        this.teams = [];
        this.format = 'TEAM';
        this.startDate = Timestamp.now();
    }

    public static async getInstance(): Promise<PeepoSprintConfig> {
        if (!PeepoSprintConfig.instance) {
            if (!PeepoSprintConfig.loadingPromise) {
                PeepoSprintConfig.loadingPromise = new PeepoSprintConfig().loadConfigFromFirebase();
            } else {
                Logger.warn('Config is already loading, returning the existing promise');
            }
            PeepoSprintConfig.instance = await PeepoSprintConfig.loadingPromise;
            PeepoSprintConfig.loadingPromise = null; // Reset the promise after loading
        }
        return PeepoSprintConfig.instance;
    }

    private async loadConfigFromFirebase(): Promise<PeepoSprintConfig> {
        const config = await fetchConfig();
        if (config) {
            this.eventId = config.eventId;
            this.players = config.players;
            this.teams = config.teams;
            this.format = config.format;
            this.startDate = config.startDate;
            Logger.debug('Loaded config from Firebase', this);
        } else {
            throw new Error('Could not find a config document in Firebase');
        }
        return this;
    }

    public isEventStarted() {
        return this.startDate.toMillis() < Date.now();
    }

    public getPlayerImage(name: string) {
        return `https://peeposprint.at/assets/member/${name.toLowerCase()}.png`;
    }

    public getTeamById(id: number) {
        const find = this.teams.find(team => team.id === id);
        if (!find) {
            return {
                id: 0,
                name: 'Unknown',
                color: '#808080',
                members: ['Unknown', 'Unknown']
            };
        }
        return find;
    }

    public getPlayer(ingName: string) {
        const find = this.players.find(player => player.ingName === ingName);
        console.log('Could not find player for:', ingName);
        if (!find) {
            return {
                name: 'Unknown',
                ingName: 'Unknown',
                socials: ['Unknown', 'Unknown'],
                team: 0,
                category: 'Unknown'
            };
        }
        return find;
    }
}
