
export enum LogLevel {
    ERROR = 0,
    WARN = 1,
    INFO = 2,
    SUCCESS = -1,
    DEBUG = 4,
    DEBUG_ADV
}

export class Logger {
    public readonly level: LogLevel;

    public static global = new Logger(LogLevel.INFO);

    constructor(level: LogLevel) {
        this.level = level || LogLevel.INFO;
    }

    private static colorize(level: LogLevel, message: string) {
        const colors = {
            [LogLevel.ERROR]: '\x1b[31m', // Red
            [LogLevel.WARN]: '\x1b[33m', // Yellow
            [LogLevel.INFO]: '', // White
            [LogLevel.DEBUG]: '\x1b[90m', // Gray
            [LogLevel.SUCCESS]: '\x1b[32m', // Green
            [LogLevel.DEBUG_ADV]: '\x1b[90m', // Green
        };
        const timestamp = new Date().toISOString().replace('T', ' ').substring(0, 19); // Format as 'YYYY-MM-DD HH:mm:ss'

        return `[${timestamp} ${LogLevel[level]}]${colors[level]} ${message}\x1b[0m`;
    }

    public static commandMessage(message: string) {
        return `[${new Date().toISOString().replace('T', ' ').substring(0, 19)} COMMAND] ${message}`;
    }

    private static log(level: LogLevel, message: string, ...args: any[]) {
        if (level <= Logger.global.level) {
            console.log(Logger.colorize(level, message), ...args);
            // Only log in production environment

        }
    }



    static default(message: string, ...args: any[]) {
        console.log('\x1b[90m' + message, ...args);
    }

    static error(message: string, ...args: any[]) {
        Logger.log(LogLevel.ERROR, message, ...args);
    }

    static warn(message: string, ...args: any[]) {
        Logger.log(LogLevel.WARN, message, ...args);
    }

    static info(message: string, ...args: any[]) {
        Logger.log(LogLevel.INFO, message, ...args);
    }

    static debugAdv(message: string, ...args: any[]) {
        Logger.log(LogLevel.DEBUG_ADV, message.length > 1000 ? message.substring(0, 1000) + "...." : message, ...args);
    }

    static success(message: string, ...args: any[]) {
        Logger.log(LogLevel.SUCCESS, message, ...args);
    }

    static debug(message: string, ...args: any[]) {
        Logger.log(LogLevel.DEBUG, message, ...args);
    }

    static setGlobalLevel(level: LogLevel) {
        Logger.global = new Logger(level);
    }
}
