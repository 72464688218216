import React, {FC} from 'react';
import styles from './Avatar.module.css';

interface AvatarProps {
    image1?: string;
    image2?: string;
    size?: number;
    team?: number;
    distance?: number;
}

export const Avatar: FC<AvatarProps> = ({
                                            image1 = undefined,
                                            image2 = undefined,
                                            team = undefined,
                                            size = 140,
                                            distance = 20
                                        }) => {
    const avatarStyle = {
        width: `${size}px`,
        height: `${size}px`,
        padding: `${distance}px`,
    };



    return (
        <div className={styles.avatar} style={avatarStyle}>
            {image1 && image2 && <>
                <img src={image1} alt="Avatar 1" className={styles.img1}/>
                <img src={image2} alt="Avatar 2" className={styles.img2}/>
            </>}
            {team && <img src={`https://peeposprint.at/assets/team/team_${team}.webp`} alt="Avatar 2" className={styles.imgTeam} />}
        </div>
    );
}
