import {getPlayerImage} from '../../../utils/firebase_consts';
import {Player} from '../../../types/config';
import {TeamN} from '../../../types/teams';

export type MemberData = {
    name: string;
    team: TeamN;
    avatar: string;
    insta?: string;
    twitch?: string;
    youtube?: string;
    twitter?: string;

}


export const mapToWebsitePlayer = (teams: TeamN[]) => (player: Player): MemberData => {
    let find = teams.find(team => team.members.includes(player.ingName));
    const socials = {} as any;
    player.socials?.forEach((social) => {
        if(social.type === 'TWITTER') {
            socials.twitter = social;
        } else if(social.type === 'TWITCH') {
            socials.twitch = social;
        } else if(social.type === 'YOUTUBE') {
            socials.youtube = social;
        } else if(social.type === 'INSTAGRAM') {
            socials.insta = social;
        }
    });

    return {
        name: player.name,
        team: find ? find : 'Unknown',
        avatar: getPlayerImage(player.name),
       ...socials
    }
}
