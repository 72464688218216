import * as React from 'react';
import {useTabs} from '../../../../components/tabs/TabsProvider';
import Button from '../../../../components/button/Button';
import {INFO_DATA} from '../offline_info_data';
import {Tab} from '../../../../components/tabs/Tab';
import {OfflineInfoSlide} from './OfflineInfoSlide';
import styles from './OfflineInfoTabs.module.css';

type RulesTabsProps = {
    small: boolean;
}

export const OfflineInfoTabs: React.FC<RulesTabsProps> = ({small}) => {
    const {changeTab} = useTabs();

    const handleChangeTab = (tab: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        changeTab(tab);
    }

    return (
        <div className={styles.rulesSectionContainer}>
            <div className={styles.tabsHeader}>
                {INFO_DATA.map((section, index) => (
                    <Button key={section.value} onClick={handleChangeTab(section.value)}>{section.title}</Button>
                ))}
            </div>
            <div className={styles.tabsContent}>
                {INFO_DATA.map((section, index) => (
                    <Tab key={section.value} value={section.value} className={small ? styles.smallTabContent : styles.tabContent}>
                        {section.slides.map((slide) => (<OfflineInfoSlide key={slide.title} data={slide} />))}
                    </Tab>
                ))}
            </div>
        </div>
    );
};
