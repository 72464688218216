import * as React from 'react';
import {useMemo} from 'react';
import styles from './PlayerSection.module.css'
import {mapToWebsitePlayer} from './player_data';
import {PlayerSlide} from './PlayerSlide';
import {useConfig} from '../../../components/ConfigContext';

type PlayerSectionProps = {
    small: boolean;
}

export const PlayersSection: React.FC<PlayerSectionProps> = ({small}) => {
    const {players, teams} = useConfig();

    const members = useMemo(() => {
        return players.map(mapToWebsitePlayer(teams)).sort((a, b) => b.team.id - a.team.id);
    }, [players]);

    return (
        <div className={styles.playerSection}>
            <div className={'page-wrapper center'}>
                <h2 style={{textAlign: 'center'}}>Players</h2>
                <div className={small ? styles.smallPlayersContainer : styles.playersContainer}>
                    {!players && <p>Players loading</p>}
                    {members && members.map((member) =>
                        <PlayerSlide key={member.name} data={member} small={small}/>)}
                </div>
            </div>
        </div>
    );
};
