import * as React from 'react';
import styles from './LiveStreamPaperSection.module.css';
import {LiveStreamPaper} from './component/LiveStreamPaper';

export const LiveStreamPaperSection: React.FC = (props) => {
    return (
        <div className={`section ${styles.liveStreamSection}`}>
            <LiveStreamPaper/>
        </div>
    );
};