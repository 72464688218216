import React, {useState} from 'react';
import styles from './Accordion.module.css';

interface AccordionProps {
    title: string;
    children?: React.ReactNode;
    className?: string; // Add className prop
}

const Accordion: React.FC<AccordionProps> = ({ title, children, className }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${styles.accordion} ${isOpen ? styles.open : ''} ${className}`}>
            <div className={styles.accordionHeader} onClick={toggleAccordion}>
                <h4>{title}</h4>
                <span className={styles.accordionIcon}>{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && <div className={styles.accordionContent}>{children}</div>}
        </div>
    );
};

export default Accordion;
