export const HOME_ROUTE = '/';
export const LIVE_EVENT_ROUTE = '/live';
export const INFORMATION_ROUTE = '/faq';
export const OFFLINE_EVENT_ROUTE = '/offline';
export const COMING_SOON_ROUTE = '/soon';

export const IMPRINT_ROUTE = '/imprint';
export const PRIVACY_ROUTE = '/privacy';
export const PREVIEW_ROUTE = '/preview';


export const QUALIFIER_REGISTER_ROUTE = '/register';
export const QUALIFIER_LEADERBOARD_ROUTE = '/leaderboard';
export const QUALIFIER_INFO_ROUTE = '/info';


