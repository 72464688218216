import * as React from 'react';
import Button from '../../components/button/Button';
import {Paper} from '../../components/paper/Paper';
import {Slider} from '../../components/slider/Slider';
import {FaqSection} from '../information/faq/FaqSection';
import {Countdown} from '../home/sections/countdown/component/Countdown';
import {LiveStreamPaper} from '../home/sections/live/component/LiveStreamPaper';
import {PartnerContainer} from '../../components/partners/PartnerContainer';
import {HomeLeaderboardSection} from '../home/sections/leaderboard/HomeLeaderboardSection';
import {IntroductionPaper} from '../information/intro/component/IntroductionPaper';
import {MapPlayerLeaderboardRow} from '../live_event/leaderboard/leaderboard/row/MapPlayerLeaderboardRow';
import {Checkpoint} from '../../utils/leaderboard_const';
import {TeamLeaderboardRow} from '../live_event/leaderboard/leaderboard/row/TeamLeaderboardRow';
import {Team} from '../../utils/firebase_consts';
import {PlayerLeaderboardRow} from '../live_event/leaderboard/leaderboard/row/PlayerLeaderboardRow';


export const PreviewPage: React.FC = (props) => {
    return (
        <div style={{marginLeft: 50}}>
            <div style={{marginBottom: 120}}>
                <h1>Headline 1</h1>
                <h2>Headline 2</h2>
                <h3>Headline 3</h3>
                <h4>Headline 4</h4>
                <h5>Headline 5</h5>
                <h6>Headline 6</h6>
                <p>Body</p>
                <span>Span</span>
            </div>
            <Button>Test button</Button>
            <Button color={'secondary'} variant={'outlined'}>Test button</Button>
            <Button variant={'static'}>Test button</Button>
            <div style={{margin: 50}}>
                <Paper/>
            </div>
            <div style={{margin: 50}}>
                <LiveStreamPaper/>
            </div>
            <div style={{margin: 50}}>
                <Paper>
                    <Slider>
                        <div>Slide 1</div>
                        <div>Slide 2</div>
                        <div>Slide 3</div>
                    </Slider>
                </Paper>
            </div>

            <div style={{margin: 50}}>
                <FaqSection/>
            </div>


            <div style={{margin: 50}}>
                <PartnerContainer/>
            </div>


            <div style={{margin: 50}}>
                <HomeLeaderboardSection/>

            </div>

            <div style={{margin: 50, backgroundColor: 'var(--bg)'}}>
                <MapPlayerLeaderboardRow row={{
                    playerName: 'Test',
                    team: 0,
                    rank: 1,
                    rankingPoints: 100,
                    ticks: 1000,
                    progressPoints: 1,
                    checkpoint: Checkpoint.END_IN,
                    progressValue: 1.0,
                    stats: {},
                    progress: {}
                }}/>
            </div>

            <div style={{margin: 50, backgroundColor: 'var(--bg)'}}>
                <TeamLeaderboardRow row={{
                    rank: 1,
                    rankingPoints: 0,
                    ticks: 0,
                    team: Team.ENDER_WARRIORS
                }}/>
            </div>


            <div style={{margin: 50, backgroundColor: 'var(--bg)'}}>
                <PlayerLeaderboardRow row={{
                    rank: 1,
                    rankingPoints: 0,
                    ticks: 0,
                    team: Team.ENDER_WARRIORS,
                    playerName: 'Test'
                }}/>
            </div>


            <div style={{marginBottom: 400}}>
                <IntroductionPaper/>
            </div>


            <div style={{marginBottom: 400}}>
                <IntroductionPaper/>
            </div>
        </div>
    );
};
