import * as React from 'react';
import styles from './NewsLetterSignup.module.css';
import QrCode from '../../../../../assets/website/wa.png';

export const NewsLetterSignup: React.FC = (props) => {

    // const handleSignup = (e: React.FormEvent) => {
    //     e.preventDefault()
    // }


return (
    <div className={styles.newsLetterSignupContainer}>
        <p>Be part of the family</p>
        <h2>join us for<br/> upcoming news</h2>
        {/*<p>Coming soon!</p>*/}
        <a href={'https://whatsapp.com/channel/0029VaDetqN9Bb5yJKifAj16'} target={'_blank'}>
            <img src={QrCode} alt={'qr code to web whatsapp gruppe von Veni'} style={{width: 230, height: 230, marginTop: 20}}/>
        </a>

        {/*<form onSubmit={handleSignup} className={styles.signUpForm}>*/}
        {/*    <input type="email" className={styles.emailInput} />*/}
        {/*    <Button className={styles.signUpButton}>Senden</Button>*/}
        {/*</form>*/}
    </div>
);
}
;
