import * as React from 'react';
import {Avatar} from './avatar/Avatar';
import styles from './GlobalLeaderboardRow.module.css';
import {TeamLeaderboardEntry} from '../../../../../types/leaderboard';
import {getTime} from '../../../../../utils/timing';
import {useConfig} from '../../../../../components/ConfigContext';


interface GlobalLeaderboardRowProps {
    data: TeamLeaderboardEntry;
}

export const GlobalLeaderboardRow: React.FC<GlobalLeaderboardRowProps> = ({data}) => {
    const {rank, team, ticks, rankingPoints} = data;
    const peepoSprintConfig = useConfig();
    const teamData = peepoSprintConfig.getTeamById(team);
    const prettyMembers = teamData.members.map((member) => peepoSprintConfig.getPlayer(member));
    const timeString = ticks > 1 ? getTime(ticks) : '-';
    const points = String(rankingPoints).padStart(2, '0');
    return (
        <div className={styles.leaderboardRow}>

            <h3 className={styles.rankText}>{rank}</h3>
            <div className={styles.profileContainer}>
                <Avatar size={64} distance={5} team={team}/>
                <div className={styles.nameContainer}>
                    <h5 style={{color: '#FFB74B'}}>{teamData.name}</h5>
                    {teamData.members && prettyMembers && prettyMembers.map((member) =>
                        <h4 key={member.name} style={{color: '#B5B5B5'}}>{member.name}</h4>)
                    }
                </div>
            </div>
            <div className={styles.pointsContainer}>
                <p style={{color: '#B5B5B5'}}>{timeString}</p>
                <p style={{color: '#10D2C4'}}>{points} P</p>
            </div>
            {/*<div className={styles.emblemContainer}>*/}
            {/*    <p>B</p>*/}
            {/*    <p>B</p>*/}
            {/*    <p>B</p>*/}
            {/*</div>*/}

        </div>
    );
};
