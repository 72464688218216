import * as React from 'react';
import './Button.css';

type ButtonProps = {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    variant?: 'filled' | 'outlined' | 'static'
    color?: 'primary' | 'secondary' | 'danger' | 'warning' | 'success';
    animated?: boolean;
    type?: 'button' | 'submit' | 'reset';
    className?: string
    disabled?: boolean;
    selected?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, variant = 'filled', color = 'primary', animated = true, type = 'button', className, disabled = false, selected = false  }) => {
    let useColor = (color === 'primary' || color === 'secondary') ? 'var(--' + color + ')' : color
    let darkColor = (color === 'primary' || color === 'secondary') ? 'var(--' + color + '-dark)' : color
    const active = selected ? 'passive' : 'active';

    if(selected){
        let buffer = useColor;
        useColor = darkColor;
        darkColor = buffer;
    }

    return (
        <button className={className ? `btn ${active} ${className}` :`btn ${active}`} onClick={onClick} style={{ ['--base-color' as any]: useColor,  ['--dark-color' as any]: darkColor}} type={type} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
