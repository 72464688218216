import * as React from 'react';
import {RbSlideSmall} from './slides/RbSlideSmall';
import {ZaveitSmall} from './slides/ZaveitSmall';

export const HomeSliderSmall: React.FC = (props) => {
    return (
        <div className={`page-wrapper`}>
            <RbSlideSmall/>
            {/*<ZaveitSmall />*/}
        </div>
    );
};
