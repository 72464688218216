import * as React from 'react';
import {useEffect, useState} from 'react';
import styles from './Leaderboard.module.css';

import {LeaderboardType,} from '../../../../utils/leaderboard_const';
import {getGlobalLeaderboard, getMapLeaderboard} from '../../../../firebase/firebase';
import {NetworkState} from '../../../../types/network';
import {
    Leaderboard,
    PlayerLeaderboardEntry,
    PlayerMapLeaderboardEntry,
    TeamLeaderboardEntry
} from '../../../../types/leaderboard';
import {Unsubscribe} from 'firebase/database';
import {useDocumentVisible} from '../../../../hooks/useIsActive';
import {useConfig} from '../../../../components/ConfigContext';


export interface LiveLeaderboardMask {
    id: string;
    tabName: string;
    global?: boolean;
    title?: string;
    hasCheckpoints?: boolean;
    hasSeed?: boolean;
}

interface LiveLeaderboardProps {
    leaderboardMask: LiveLeaderboardMask;
    playerRowRender: (results: PlayerLeaderboardEntry | PlayerMapLeaderboardEntry) => React.ReactNode;
    teamRowRender: (results: TeamLeaderboardEntry) => React.ReactNode;
}


export const LiveLeaderboard: React.FC<LiveLeaderboardProps> = ({leaderboardMask, playerRowRender, teamRowRender}) => {
    const [mode, setMode] = useState<LeaderboardType>(LeaderboardType.PLAYERS);
    const [leaderboard, setLeaderboard] = useState<Leaderboard<any>>();
    const [networkState, setNetworkState] = useState<NetworkState>({
        loading: true,
        error: false,
        errorMessage: undefined
    })
    let visible = useDocumentVisible();
    const config = useConfig();
    const isPlayers = mode === LeaderboardType.PLAYERS;

    useEffect(() => {
        let unsubscribe: Unsubscribe = () => {};

        function fetchLeaderboard() {
            if (leaderboardMask.global) {
                unsubscribe = getGlobalLeaderboard(mode, (data) => {
                    setLeaderboard(data);
                    setNetworkState({loading: false, error: false, errorMessage: undefined});
                    console.log('Setting global leaderboard', data);
                });
            } else {
                unsubscribe = getMapLeaderboard(leaderboardMask.id, mode, (data) => {
                    setLeaderboard(data);
                    setNetworkState({loading: false, error: false, errorMessage: undefined});
                    console.log('Setting map leaderboard', data);
                });
            }
        }

        if (visible) {
            setNetworkState({loading: true, error: false, errorMessage: undefined});
            fetchLeaderboard();
        } else {
            unsubscribe();
        }

        return () => {
            unsubscribe();
        }
    }, [mode, visible]);

    const handleSwitch = (newMode: LeaderboardType) => (e: React.MouseEvent) => {
        e.preventDefault();
        if(newMode === mode) {
            return;
        }
        setMode(newMode);
    }

    const getHeaderText = () => {
        if (leaderboard?.hasOwnProperty('seed')) {
            // @ts-ignore
            return `Seed: ${leaderboard.seed}`;
        } else {
            return '-';
        }
    }

    return (
        <div className={styles.leaderboardContainer}>
            <div className={styles.topControls}>
                {leaderboard && <span className={styles.seedText}>{getHeaderText()}</span>}
                {!leaderboard && config.isEventStarted() && <span className={styles.loadingText}>Loading...</span>}
                {!leaderboard && !config.isEventStarted() && <span className={styles.loadingText}>Waiting for Event ...</span>}
                <div className={styles.switch}>
                    <span onClick={handleSwitch(LeaderboardType.PLAYERS)} className={`${isPlayers ? styles.active : null} clickable`}>{isPlayers ? (
                        <b>Players</b>) : 'Players'}</span>
                    <span> | </span>
                    <span onClick={handleSwitch(LeaderboardType.TEAM)} className={`${!isPlayers ? styles.active : null} clickable`}>{!isPlayers ? (
                        <b>Teams</b>) : 'Teams'}</span>
                </div>
            </div>
            <div className={styles.leaderboardContainer}>

                {leaderboard && mode === LeaderboardType.PLAYERS &&
                    // @ts-ignore
                    leaderboard?.results?.map(playerRowRender)}
                {leaderboard && mode === LeaderboardType.TEAM && leaderboard?.results?.map(teamRowRender)}
                {leaderboard && mode === LeaderboardType.TEAM &&( !leaderboard?.results || !leaderboard?.results.length) && <p>No data available</p>}
                {/*{networkState?.loading && <p>Loading...</p>}*/}
                {networkState?.error && <p>{networkState.errorMessage}</p>}
                {!networkState?.error &&  !networkState?.loading && !leaderboard && <p>No leaderboard available</p>}
            </div>
        </div>
    );
};
