import * as React from 'react';
import {TabsProvider} from '../../../components/tabs/TabsProvider';
import {OfflineInfoTabs} from './components/OfflineInfoTabs';
import {INFO_DATA} from './offline_info_data';
import styles from './OfflineInfoSection.module.css';
import {Link} from 'react-router-dom';

type OfflineInfoProps = {
    small: boolean;
}

export const OfflineInfoSection: React.FC<OfflineInfoProps> = ({small}) => {
    const defaultVal =  INFO_DATA.length ? INFO_DATA[0].value : undefined;

    return (
        <>
            <div className={`section ${styles.container}`}>
                <div className={`page-wrapper center ${styles.rulesSection}`}>
                    <h2>Alles wichtige</h2>
                    <p className={styles.rulesSubtitle}>Alle Infos gibt es hier! Falls du noch offene Fragen hast kannst du uns
                        gerne unter <Link to={'mailto:business@peepoSprint.at'} style={{textDecoration: 'underline'}}>business@peepoSprint.at</Link> kontaktieren.</p>
                   <div className={styles.rulesContent}>
                       <TabsProvider defaultVal={defaultVal}>
                           <OfflineInfoTabs small={small} />
                       </TabsProvider>
                   </div>
                </div>
            </div>
        </>

    );
};
