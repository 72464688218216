import * as React from 'react';
import styles from './TimePart.module.css';


type TimePartProps = {
    time: number;
    label: string;
}

export const TimePart: React.FC<TimePartProps> = ({time, label}) => {
    const formattedTime = time.toString().padStart(2, '0');
    return (
        <div className={styles.timePartContainer}>
            <h1 className={styles.heading}>{formattedTime}</h1>
            <h2 className={styles.label}>{label}</h2>
        </div>
    );
};