import * as React from 'react';
import styles from './footer.module.css';
import TwitchLogo from '../../assets/website/brands/twitch_logo.png';
import {NavLink} from 'react-router-dom';
import {IMPRINT_ROUTE, PRIVACY_ROUTE} from '../../router/routerUrls';

export const Footer: React.FC = (props) => {
    const handleOpenTwitch =  (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        window.open('https://www.twitch.tv/veni', '_blank');
    };


    return (
        <footer className={styles.footer}>
            <div className={'page-wrapper center ' + styles.footerContainer}>

                <div className={styles.twitchText} onClick={handleOpenTwitch}>
                    <img src={TwitchLogo} alt={'Logo of twtich'}/>
                    <p className={styles.footerText}>@Veni</p>
                </div>
                <div className={styles.middleContainer}>
                    <p className={styles.footerText}>
                        <NavLink to={IMPRINT_ROUTE}>Impressum</NavLink>
                        <span style={{marginRight: 10, marginLeft: 10}}>|</span>
                        <NavLink to={PRIVACY_ROUTE}>Privacy</NavLink>
                    </p>
                </div>
                <span className={styles.copyrightText}>©2024 peepoSprint<br/> designed by <a
                    href={'https://raffaela-pichler.at/'} target={'_blank'}
                    rel="noreferrer" style={{textDecoration: 'underline'}}>Raffaela Pichler</a></span>
            </div>
            <div className={styles.buildNumber}>Version: {process.env.REACT_APP_VERSION}</div>
        </footer>
    );
};
