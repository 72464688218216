import React, { useEffect, useRef, useState, createContext, useContext } from 'react';
import styles from './Slider.module.css';

type SliderContextType = {
    currentSlide: number;
    setCurrentSlide: (index: number) => void;
    resetInterval: () => void;
    pauseSlider: (seconds: number) => void;
};

const SliderContext = createContext<SliderContextType | undefined>(undefined);

type SliderProps = {
    children?: React.ReactNode;
    className?: string;
};

export const Slider: React.FC<SliderProps> = ({ children, className }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideInterval = useRef<NodeJS.Timeout | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const startSlideShow = () => {
        slideInterval.current = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === React.Children.count(children) - 1 ? 0 : prevSlide + 1
            );
        }, 12 * 1000);
    };

    const resetInterval = () => {
        if (slideInterval.current) {
            clearInterval(slideInterval.current);
        }
        startSlideShow();
    };

    const pauseSlider = (seconds: number) => {
        if (slideInterval.current) {
            clearInterval(slideInterval.current);
        }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            startSlideShow();
        }, seconds * 1000);
    };

    useEffect(() => {
        startSlideShow();
        return () => {
            if (slideInterval.current) {
                clearInterval(slideInterval.current);
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [children]);

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? React.Children.count(children) - 1 : prevSlide - 1
        );
        resetInterval();
    };

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === React.Children.count(children) - 1 ? 0 : prevSlide + 1
        );
        resetInterval();
    };

    return (
        <SliderContext.Provider value={{ currentSlide, setCurrentSlide, resetInterval, pauseSlider }}>
            <div className={`${styles.slider} ${className}`}>
                <button className={`${styles.sliderControl} ${styles.sliderPrev}`} onClick={handlePrevSlide}></button>
                <button className={`${styles.sliderControl} ${styles.sliderNext}`} onClick={handleNextSlide}></button>

                <div className={styles.sliderIndicators}>
                    {React.Children.map(children, (_, index) => (
                        <span
                            key={index}
                            className={`${styles.sliderIndicator} ${index === currentSlide ? `${styles.active}` : ''}`}
                        />
                    ))}
                </div>
                <div
                    className={styles.slidesContainer}
                    style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                    }}
                >
                    {React.Children.map(children, (child, index) => (
                        <div
                            key={index}
                            className={`${styles.slide} ${index === currentSlide ? `${styles.active}` : ''}`}
                        >
                            {child}
                        </div>
                    ))}
                </div>
            </div>
        </SliderContext.Provider>
    );
};

export const useSliderContext = (): SliderContextType => {
    const context = useContext(SliderContext);
    if (!context) {
        throw new Error('useSliderContext must be used within a Slider');
    }
    return context;
};
