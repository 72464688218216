import React from 'react';
import styles from './SmallNavbar.module.css';
import {PeepoLogo} from '../../components/PeepoLogo';
import {NavLink} from 'react-router-dom';
import {HOME_ROUTE, INFORMATION_ROUTE, OFFLINE_EVENT_ROUTE, LIVE_EVENT_ROUTE} from '../../router/routerUrls';
import {LIVE_LEADERBOARD_SECTION_ID} from "../live_event/leaderboard/LiveLeaderboardSection";
import Button from "../../components/button/Button";
import {isEventLive} from "../../components/navbar/Navbar";
import {useConfig} from "../../components/ConfigContext";

interface SmallNavbarProps {
    // Props definition
}

export const SmallNavbar: React.FC<SmallNavbarProps> = () => {

    const [open, setOpen] = React.useState(false);
    let peepoSprintConfig = useConfig();
    const live = isEventLive(peepoSprintConfig.startDate?.toDate());

    const handleOpen = () => setOpen(!open);

    return (
        <div className={styles.container}>
            <div className={styles.navBarContainer}>
                <NavLink to={HOME_ROUTE} style={{cursor: 'pointer', zIndex: 1000}}><PeepoLogo/></NavLink>

                {open ? (
                    <Button color={'secondary'} onClick={handleOpen}>Close</Button>
                ) : (
                    <Button color={'secondary'} onClick={handleOpen}>Menu</Button>
                )}
            </div>

            {open && (
                <div className={styles.menu}>
                    <ul className={styles.fixedNavbarItemContainer}>
                        <li className={live ? `${styles.fixedNavbarItem} ${styles.live}` : styles.fixedNavbarItem}>
                            <NavLink to={LIVE_EVENT_ROUTE}
                                     onClick={handleOpen}
                                     className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                                Event
                            </NavLink>
                        </li>
                        <li className={styles.fixedNavbarItem}>
                            <NavLink to={INFORMATION_ROUTE}
                                     onClick={handleOpen}
                                     className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                                Turnier Information
                            </NavLink>
                        </li>
                        <li className={styles.fixedNavbarItem}>
                            <NavLink to={OFFLINE_EVENT_ROUTE}
                                     onClick={handleOpen}
                                     className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                                Timetable
                            </NavLink>
                        </li>
                        <li className={styles.fixedNavbarItem}>
                            <NavLink to={LIVE_EVENT_ROUTE + `#${LIVE_LEADERBOARD_SECTION_ID}`}
                                     onClick={handleOpen}
                                     className={styles.navbarLink}>
                                <Button color={'secondary'}>Leaderboard</Button></NavLink>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
