import * as React from 'react';
import styles from './CountdownSection.module.css';
import {Countdown} from './component/Countdown';
import {useConfig} from '../../../../components/ConfigContext';

export const CountdownSection: React.FC = (props) => {
    const peepoSprintConfig = useConfig();
    const END_DATE = peepoSprintConfig.startDate?.toDate();
    const duration = 9*60*60; // 8 hours
    console.log('End Date:', END_DATE);
    console.log('PeepoConfig', peepoSprintConfig)
    return (
        <div className={styles.countdownSection}>
            <div className={styles.layer2} />
            <div className={styles.countdownWrapper}>
                <Countdown targetDate={END_DATE} duration={duration} liveComponent={<h1>Live</h1>} fallbackComponent={<h1>Test</h1>}/>
            </div>
        </div>
    );
};
