import React from 'react';
import styles from './MonsterPaper.module.css';
import {Paper} from './Paper';

import MonsterImage from '../../assets/website/home/monster_block.png';

interface MonsterCardProps {
    // Props definition
    children?: React.ReactNode;
}

export const MonsterPaper: React.FC<MonsterCardProps> = ({children}) => {
    // Component implementation
    return (
        <Paper classname={styles.container}>
            <img src={MonsterImage} width={530} height={420} className={styles.monsterContainer}
                 alt={'A head build out of blocks. just decorative'}/>

            <div className={styles.contentContainer}>
                {children}
            </div>

        </Paper>
    );
};
