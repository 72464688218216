import * as React from 'react';
import {Paper} from '../../../../../components/paper/Paper';
import {Slider} from '../../../../../components/slider/Slider';
import {RbSlide} from './slides/RbSlide';
import {XperionSlide} from './slides/XperionSlide';

export const HomeSlider: React.FC = (props) => {
    return (
        <Paper classname={`page-wrapper`} color={'#0E121A'} lightColor={'#253042'} pattern={false} >
            <Slider>
                <RbSlide/>
                <XperionSlide />
            </Slider>
        </Paper>
    );
};
