import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from './Countdown.module.css';
import { TimePart } from './TimePart';
import { getRemainingTime } from '../../../../../utils/timing';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button/Button';
import XPERION from '../../../../../assets/website/partner/xperion_logo.png';
import {LIVE_EVENT_ROUTE} from '../../../../../router/routerUrls';

type CountdownProps = {
    targetDate: Date;
    duration: number; // Duration of the event in seconds
    liveComponent: React.ReactNode; // Between Target and target + duration
    fallbackComponent: React.ReactNode; // Add FallbackComponent as a prop
};


function determineDate(targetDate: Date, duration: number) {
    const currentTime = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const endTime = targetTime + duration * 1000;

    if (currentTime < targetTime) {
        return 'pre';
    } else if (currentTime >= targetTime && currentTime <= endTime) {
        return 'live'
    } else if (currentTime > endTime) {
       return 'post';
    }
    return 'live';
}


// TODO use fallback component and this stuff
export const Countdown: React.FC<CountdownProps> = ({ targetDate, duration , liveComponent, fallbackComponent }) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(targetDate));
    const [status, setStatus] = useState<'pre' | 'live' | 'post'>(determineDate(targetDate, duration));

    useEffect(() => {
        const interval = setInterval(() => {
            let newState = determineDate(targetDate, duration);
            switch (newState) {
                case 'pre':
                    setStatus('pre');
                    setRemainingTime(getRemainingTime(targetDate));
                    break;
                case 'live':
                    setStatus('live');
                    break;
                case 'post':
                    setStatus('post');
                    break;
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [targetDate, duration]);

    if (status === 'post') {
        return (<div className={styles.countdownContainer}>
            <span className={styles.spacedText}><b>PEEPOSPRINT</b> ist leider schon vorbei!</span>
            <div className={styles.timeContainer}>
                <h4 style={{textAlign: 'center'}}>Sei gespannt auf das Nächste mal! Sobald ein neues Datum fest steht siehst du es hier auf der Website</h4>
            </div>
            <div className={styles.ticketWrapper}>
                <Link to={LIVE_EVENT_ROUTE} rel={'noopener noreferrer'}
                      style={{paddingRight: 30}}>
                    <Button>Zum letzten Event</Button>
                </Link>
                <Link to={'https://www.twitch.tv/veni'} style={{paddingLeft: 30}}>
                    <Button color={'secondary'}>Veni</Button>
                </Link>
            </div>
        </div>);
    }

    if (status === 'live') {
        return (<div className={styles.countdownContainer}>
            <span className={styles.spacedText}><b>PEEPOSPRINT</b> ist</span>
            <div className={styles.timeContainer}>
                <h2>LIVE</h2>
            </div>
            <h4 className={styles.ticketText}>Heute ab 14:00 live aus dem <Link
                to={'https://www.mediamarkt.de/de/specials/xperion'}
                rel={'noopener noreferrer'}
                style={{textDecoration: 'underline'}}>XPERION</Link> in KÖLN!</h4>
            <div className={styles.ticketWrapper}>
                <Link to={LIVE_EVENT_ROUTE} rel={'noopener noreferrer'}
                      style={{paddingRight: 30}}>
                    <Button>Zum Event</Button>
                </Link>
                <Link to={'https://www.twitch.tv/veni'} style={{paddingLeft: 30}}>
                    <Button color={'secondary'}>Stream (Twitch)</Button>
                </Link>
            </div>
            <h4>Hosted by XPERION</h4>
            <Link to={'https://www.mediamarkt.de/de/specials/xperion'} target={'_blank'} rel={'noopener noreferrer'}>
                <img src={XPERION} alt={'Logo of XPERION which is hosting the event'} width={350} height={'auto'}/>
            </Link>
        </div>);
    }

    return (
        <div className={styles.countdownContainer}>
            <span className={styles.spacedText}><b>PEEPOSPRINT</b> starts in</span>
            <div className={styles.timeContainer}>
                <TimePart time={remainingTime.days} label={'days'}/>
                <span className={styles.separator}>:</span>
                <TimePart time={remainingTime.hours} label={'hours'}/>
                <span className={styles.separator}>:</span>
                <TimePart time={remainingTime.minutes} label={'minutes'}/>
                <span className={styles.separator}>:</span>
                <TimePart time={remainingTime.seconds} label={'seconds'}/>
            </div>
            <h4 className={styles.ticketText}>Sei jetzt live vor Ort dabei! Am 13.07 im <Link
                to={'https://www.mediamarkt.de/de/specials/xperion'}
                rel={'noopener noreferrer'}
                style={{textDecoration: 'underline'}}>XPERION</Link> in KÖLN!</h4>
            <div className={styles.ticketWrapper}>
                <Link to={'https://app.tickettailor.com/events/peeposprint/1293088'} rel={'noopener noreferrer'}
                      style={{paddingRight: 30}}>
                    <Button>Ticket kaufen</Button>
                </Link>
                <Link to={'https://www.twitch.tv/veni'} style={{paddingLeft: 30}}>
                    <Button color={'secondary'}>Stream</Button>
                </Link>
            </div>
            <h4>Hosted by XPERION</h4>
            <Link to={'https://www.mediamarkt.de/de/specials/xperion'} target={'_blank'} rel={'noopener noreferrer'}>
                <img src={XPERION} alt={'Logo of XPERION which is hosting the event'} width={350} height={'auto'}/>
            </Link>
        </div>
    );
};
