import React from 'react';
import Logo from '../assets/website/logo.webp';

interface PeepoLogoProps {
    height?: number;
    className?: string;
}

export const PeepoLogo: React.FC<PeepoLogoProps> = ({ height = 100, className }) => {
    return (
        <img
            src={Logo}
            alt="Peepo Logo"
            style={{ height: `${height}px`, width: 'auto' }}
            className={className}
        />
    );
};
