import * as React from 'react';
import {useEffect, useState} from 'react';
import {getGlobalLeaderboard} from '../../../../../firebase/firebase';
import {TopAvatar} from './TopAvatar';
import styles from './GlobalLeaderboard.module.css';
import {GlobalLeaderboardRow} from './GlobalLeaderboardRow';
import {TeamLeaderboard} from '../../../../../types/leaderboard';
import {Unsubscribe} from 'firebase/database';
import {LeaderboardType} from '../../../../../utils/leaderboard_const';
import {useDocumentVisible} from '../../../../../hooks/useIsActive';
import {useConfig} from '../../../../../components/ConfigContext';

export const GlobalLeaderboard: React.FC = (props) => {
    const [leaderboardData, setLeaderboardData] = useState<TeamLeaderboard>();
    const visible = useDocumentVisible();
    const peepoSprintConfig = useConfig();
    const isLive = peepoSprintConfig.isEventStarted();
    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted
        let unsubscribe: Unsubscribe = () => {};

        function fetchLeaderboard() {
            unsubscribe = getGlobalLeaderboard(LeaderboardType.TEAM, (data) => {
                if (isMounted) {
                    setLeaderboardData(data);
                }
            });
        }

        if (visible) {
            fetchLeaderboard();
        } else {
            unsubscribe();
        }

        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [visible]);

    const first = leaderboardData?.results?.find((entry) => entry.rank === 1);
    const second = leaderboardData?.results?.find((entry) => entry.rank === 2);
    const third = leaderboardData?.results?.find((entry) => entry.rank === 3);

    return (
        <div>
            <div className={styles.avatarContainer}>
                {second && <TopAvatar rank={2} team={second.team} size={100} time={second.ticks} points={second.rankingPoints} />}
                {first && <TopAvatar rank={1} team={first.team} size={140} time={first.ticks} points={first.rankingPoints}/>}
                {third && <TopAvatar rank={3} team={third.team} size={100} time={third.ticks} points={third.rankingPoints}/>}
            </div>
            <div className={styles.rowsContainer}>
                {leaderboardData && leaderboardData.results.map((entry) => (
                    <GlobalLeaderboardRow key={entry.team} data={entry}/>
                ))}
                {!leaderboardData && isLive && (
                    <h4 style={{textAlign: 'center'}}>Warte auf Daten</h4>
                )}
                {!leaderboardData && !isLive && (
                    <h4 style={{textAlign: 'center'}}>Ab Eventstart Verfügbar</h4>
                )}
            </div>

        </div>
    );
};
