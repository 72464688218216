import React from 'react';
import styles from './PlayerLeaderboardRow.module.css';
import {PlayerLeaderboardEntry} from '../../../../../types/leaderboard';
import {getTime} from '../../../../../utils/timing';
import {useConfig} from '../../../../../components/ConfigContext';

interface PlayerLeaderboardRowProps {
   row: PlayerLeaderboardEntry
}

export const PlayerLeaderboardRow: React.FC<PlayerLeaderboardRowProps> = ({row}) => {
    const {rank, playerName, ticks, rankingPoints} = row;
    const peepoSprintConfig = useConfig();
    const color = rank % 2 === 0 ? 'transparent' : 'var(--secondary-dark)';
    const timeString = ticks > 1 ? getTime(ticks) : '-';
    const player = peepoSprintConfig.getPlayer(playerName);
    const prettyName = player?.name;
    const teamString = peepoSprintConfig.getTeamById(player.team)?.name;
    // const points = String(rankingPoints).padStart(2, '0');
    return (
        <div className={styles.mapLeaderboardRow} style={{['--row-color' as any]: color}}>
            <span className={styles.rank}>{rank}</span>
            <span className={styles.name}>{prettyName}</span>
            <span className={styles.team}>{teamString}</span>
            <span className={styles.placeholder}></span>
            <span className={styles.time}>BT {timeString}</span>
            {/*<span className={styles.points}>{points}P</span>*/}
        </div>
    );
};
